<template>
  <div
    class="startScreen"
    :style="{ background: background }"
  >
    <div class="tapper__logo">
      <img
        v-if="img == 'nalubaloo'"
        style="width: 100%"
        src="@/assets/images/naluBaloo.gif"
        alt=""
      >
      <img
        v-if="img == 'allday'"
        style="width: 50%"
        src="@/assets/images/allday.gif"
        alt=""
      >
      <img
        v-if="img == 'tapper'"
        style="width: 50%"
        src="@/assets/images/white.gif"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'VDemoPreloader',
  data() {
    return {
      img: '',
      background: '',
    }
  },

  mounted() {
    //демки для разных заведений
    var full = window.location.host
    var parts = full.split('.')
    var sub = parts[0]
    if (sub == 'nalubaloo') {
      ;(this.img = 'nalubaloo'), (this.background = 'white')
    } else if (sub == 'allday') {
      ;(this.img = 'allday'), (this.background = '#375CC7')
    } else {
      ;(this.img = 'tapper'), (this.background = '#000033')
    }
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
}
</script>

<style lang="scss">
.startScreen {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #000033;
  & .tapper__logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
