<template>
  <div
    class="modal__overlay"
    @click="showEnterPhone"
  />
  <div class="enterphone-modal">
    <div class="container">
      <button
        class="closeModal"
        @click="showEnterPhone"
      >
        <img
          src="@/assets/images/close_icon.svg"
          alt="Закрыть"
        >
      </button>

      <h3 class="enterphone-modal__title">
        Phone number
      </h3>
      {{ errorPhone1 }}
      <div class="input__wrapper">
        <input
          id="phone"
          v-model="phone"
          v-maska="'+7(###) ###-##-##'"
          class="enterphone-modal__input"
          type="phone"
        >

        <label for="phone">Phone number <span>*</span></label>
      </div>

      <button
        class="main-btn"
        :disabled="errorPhone"
        @click="showEnterCode({ showEnterCode: true })"
      >
        Send the code
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Enterphone',
  directives: {
    phone: {
      beforeMount(el) {
        el.oninput = function (e) {
          if (!e.isTrusted) {
            return
          }

          let x = this.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
          this.value = !x[2]
            ? x[1]
            : '(' + x[1] + ') ' + x[2] + (x[3] ? ' - ' + x[3] : '')
          el.dispatchEvent(new Event('input'))
        }
      },
    },
  },
  data() {
    return {
      errorPhone: true,
      phone: '',
    }
  },
  watch: {
    phone: function (val) {
      let phone = this.phone.replace(/[^\+\d]/g, '')
      if (phone.length < 12) {
        this.errorPhone = true
      } else {
        this.errorPhone = false
      }
    },
  },
  methods: {
    showEnterPhone() {
      this.$emit('showEnterPhone', false)
    },

    showEnterCode() {
      this.$emit('showEnterCode', { showEnterCode: true })
    },
  },
}
</script>

<style lang="scss">
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #dcdee3;
  opacity: 0.9;

  z-index: 1;
}

.enterphone-modal {
  position: fixed;
  bottom: 45px;

  width: 100%;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0px 0px;

  & .container {
    padding-top: 12px;
  }

  & .closeModal {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    background: rgba(219, 222, 231, 0.5);
    cursor: pointer;

    border-radius: 50%;
    border: none;
  }

  &__title {
    margin-bottom: 24px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;

    color: #010d35;
  }

  & .input__wrapper {
    position: relative;
    margin-bottom: 24px;

    & label {
      position: absolute;
      top: 15px;
      left: 12px;

      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #80869a;

      & span {
        color: red;
      }
    }
  }

  &__input {
    width: 100%;
    min-height: 52px;
    padding: 12px 12px 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #010d35;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    outline: none;

    &:focus + label {
      top: 8px;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .main-btn {
    margin-bottom: 20px;
  }
}
</style>
