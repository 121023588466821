<template>
  <div
    class="modal__overlay"
    @click="showPayment"
  />
  <div class="payment__modal">
    <div class="payment__modal-header">
      <img
        src="@/assets/images/apple_pay_logo.svg"
        alt="Логотип Apple pay"
      >

      <span
        class="payment__modal-cancel"
        @click="showPayment"
      >Cancel</span>
    </div>
    <div class="container">
      <div class="choice__creditCard">
        <div class="choice__creditCard-name">
          <img
            src="@/assets/images/mastercard.svg"
            alt="Логотип Mastercard"
          >

          <span
            class="choice__creditCard-text"
          >MASTERCARD PLATINUM (•••• 2505)</span>
        </div>

        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.962891 11.9033C1.22852 11.9033 1.45264 11.812 1.63525 11.6294L6.60742 6.70703C6.83984 6.48291 6.94775 6.24219 6.94775 5.95166C6.94775 5.66113 6.83984 5.41211 6.61572 5.19629L1.63525 0.273926C1.45264 0.0913086 1.22852 0 0.962891 0C0.431641 0 0 0.42334 0 0.95459C0 1.22021 0.10791 1.46094 0.298828 1.66016L4.66504 5.95996L0.298828 10.2515C0.10791 10.4424 0 10.6831 0 10.9487C0 11.48 0.431641 11.9033 0.962891 11.9033Z"
            fill="#007AFF"
          />
        </svg>
      </div>

      <div class="payment__modal-name">
        <p>Payment: Tapper bill</p>
      </div>

      <div class="payment__modal-sum">
        <span class="payment__modal-text">Price</span>

        <span class="payment__modal-price">{{ sum }} &#8364;</span>
      </div>

      <div class="payment__modal-faceId">
        <img
          src="@/assets/images/face-id.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Payment',
  props: {
    sum: {
      type: Number,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    diffCheck: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    if (this.diffCheck) {
      let aa = this.item.filter((item) => item.active)
      aa.forEach(function (item, i) {
        aa[i].pay = true
      })
    } else {
      let aa = this.item
      aa.forEach(function (item, i) {
        aa[i].pay = true
      })
    }
  },
  methods: {
    showPayment() {
      this.$emit('showPayment', false)
      this.returnItem()
    },
    returnItem() {
      this.$emit('returnItem', this.item)
    },

    showPaidCheck() {
      this.$emit('showPaidCheck', true)
    },
  },
}
</script>

<style lang="scss">
.payment__modal {
  position: fixed;
  bottom: 0;

  width: 100%;

  font-family: 'SFPro';
  background-color: #fff;
  font-weight: 400;
  color: #000;
  z-index: 3;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px 11px;

    border-bottom: 0.5px solid #b2b2b2;

    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.035em;
    color: #007aff;
  }

  & .container {
    padding-top: 0;
  }

  & .choice__creditCard {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    border-bottom: 0.5px solid #b2b2b2;

    &-name {
      display: flex;
      align-items: center;
      margin-left: 30px;
    }

    &-text {
      max-width: 143px;
      margin-left: 14px;

      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.015em;
    }
  }

  &-name {
    display: flex;
    justify-content: center;
    padding: 12px 0;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.015em;

    border-bottom: 0.5px solid #b2b2b2;
  }

  &-sum {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 14px;

    border-bottom: 0.5px solid #b8b8b8;
  }

  &-text {
    margin-left: 84px;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.015em;
  }

  &-price {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }

  &-faceId {
    display: flex;
    justify-content: center;
    padding-top: 12px;
  }
}
</style>
