<template>
  <div class="container">
    <!-- Header -->
    <VOrderHeader
      :table-code="8"
      lang="en"
    />

    <div class="dishList">
      <div
        v-if="dishItem && notPaid != 0"
        class="divide__check"
      >
        <span class="divide__check-label">Split the bill</span>

        <label class="divide__check-switch">
          <input
            v-model="diffCheck"
            type="checkbox"
            :disabled="notPaid == 1"
            @click="diffCheckFunc"
          >

          <span class="slider" />
        </label>
      </div>

      <div
        v-if="!dishItem"
        class="emptyList"
      >
        <img
          src="@/assets/images/clock_logo.svg"
          alt="Изображение часов"
          class="emptyList__icon"
        >

        <h3 class="emptyList__heading">
          Your order will be here soon
        </h3>

        <!-- <p class="emptyList__explanation">Возможно, официант ещё не успел открыть заказ пожалуйста, подождите..</p> -->
      </div>

      <div
        v-else-if="notPaid == 0"
        class="emptyList"
      >
        <img
          src="@/assets/images/clock_logo.svg"
          alt="Изображение часов"
          class="emptyList__icon"
        >

        <h3 class="emptyList__heading">
          Order successfully paid
        </h3>

        <!-- <p class="emptyList__explanation">Возможно, официант ещё не успел открыть заказ пожалуйста, подождите..</p> -->
      </div>

      <ul
        v-else
        class="dishList__list"
      >
        <li
          v-for="(item, index) in items"
          :key="item.id"
          class="dishList__item"
          :class="{ paid: item.pay }"
        >
          <p
            v-if="!item.pay && diffCheck"
            class="dishList__item-status"
          >
            Payment required
          </p>
          <p
            v-else-if="!item.pay && !diffCheck"
            class="dishList__item-status"
          />
          <p
            v-else
            class="dishList__item-status"
          >
            Paid
          </p>

          <div class="dishList__item-dish">
            <label class="dish__checkbox">
              <input
                v-model="item.active"
                type="checkbox"
                :disabled="item.active && item.pay"
                @click="checkPosition()"
              >

              <span v-if="diffCheck" />

              <p class="dish__checkbox-text">{{ item.name }}</p>
            </label>

            <div class="dishList__item-total">
              <span class="sum">{{ item.price * item.count }} &#8364;</span>

              <div class="count">
                <button
                  class="count__btn minus"
                  :disabled="item.pay || !diffCheck"
                  @click="minus(index)"
                >
                  <svg
                    width="10"
                    height="4"
                    viewBox="0 0 10 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :style="{ visibility: !diffCheck ? 'hidden' : 'visible' }"
                  >
                    <path
                      d="M0 3.25V0.75C0.910053 0.75 1.78307 0.75 2.61905 0.75C3.46561 0.75 4.25926 0.75 5 0.75C5.74074 0.75 6.53439 0.75 7.38095 0.75C8.22751 0.75 9.10053 0.75 10 0.75V3.25C9.10053 3.25 8.22751 3.25 7.38095 3.25C6.53439 3.25 5.74074 3.25 5 3.25C4.25926 3.25 3.46561 3.25 2.61905 3.25C1.78307 3.25 0.910053 3.25 0 3.25Z"
                      fill="#808697"
                    />
                  </svg>
                </button>

                <span class="count__quantity">{{ item.count }}</span>

                <button
                  class="count__btn"
                  :disabled="
                    item.pay || !diffCheck || item.count == item.maxCount
                  "
                  @click="plus(index)"
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :style="{ visibility: !diffCheck ? 'hidden' : 'visible' }"
                  >
                    <path
                      d="M3.8208 10C3.8208 9.32728 3.8208 8.6781 3.8208 8.05247C3.8208 7.42011 3.8208 6.83148 3.8208 6.28658V3.72351C3.8208 3.17188 3.8208 2.58325 3.8208 1.95762C3.8208 1.32526 3.8208 0.672721 3.8208 0H6.1689C6.1689 0.672721 6.1689 1.32526 6.1689 1.95762C6.1689 2.58325 6.1689 3.17188 6.1689 3.72351V6.28658C6.1689 6.83148 6.1689 7.42011 6.1689 8.05247C6.1689 8.6781 6.1689 9.32728 6.1689 10H3.8208ZM0 6.12513V3.88496C0.686577 3.88496 1.34912 3.88496 1.98764 3.88496C2.63302 3.88496 3.23378 3.88496 3.78991 3.88496H6.19979C6.76279 3.88496 7.36354 3.88496 8.00206 3.88496C8.64744 3.88496 9.31342 3.88496 10 3.88496V6.12513C9.31342 6.12513 8.64744 6.12513 8.00206 6.12513C7.36354 6.12513 6.76279 6.12513 6.19979 6.12513H3.78991C3.23378 6.12513 2.63302 6.12513 1.98764 6.12513C1.34912 6.12513 0.686577 6.12513 0 6.12513Z"
                      fill="#808697"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="dishItem && notPaid != 0">
      <div class="tips">
        <span class="tips__heading">Tip</span>

        <div class="tips__waiter">
          <img
            src="@/assets/images/image_waiter.jpg"
            alt="Фото официанта"
          >

          <div class="tips__waiter-info">
            <span>Waiter</span>

            <span>Claudia</span>
          </div>
        </div>

        <div class="tips__sum">
          <span>{{ tips }}</span> &#8364;
          <span class="custom-input">
            <input
              v-maska="'######'"
              :value="tips"
              placeholder="0"
              @input="validTips($event.target.value)"
              @keyup="manualTips = true"
            ></span>
          <img
            v-if="inputTips"
            class="close-input"
            src="@/assets/images/close_icon.svg"
            alt="Закрыть"
            @click="showInputTips"
          >
          <button
            class="tips__sum-btn"
            type="reset"
            @click="resetTips"
          >
            <img
              src="@/assets/images/close_icon.svg"
              alt="Закрыть"
            >
          </button>
        </div>

        <div class="tips__percents">
          <ul class="tips__percents-list">
            <li
              class="tips__list-item"
              :class="{ active: percents == 0 && !manualTips }"
              @click="selectPercents(0)"
            >
              0%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 10 && !manualTips }"
              @click="selectPercents(10)"
            >
              10%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 15 && !manualTips }"
              @click="selectPercents(15)"
            >
              15%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 20 && !manualTips }"
              @click="selectPercents(20)"
            >
              20%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 25 && !manualTips }"
              @click="selectPercents(25)"
            >
              25%
            </li>
          </ul>
        </div>
      </div>

      <div class="check">
        <a
          href="#"
          class="check__discount"
          :class="{ active: code == '' }"
          @click.prevent="showEnterPhone"
        >Apply the discount</a>

        <div
          v-if="diffCheck"
          class="check__total"
        >
          <span class="check__total-heading">Total bill:</span>

          <span class="check__total-sum">{{ summaAll }} &#8364;</span>
        </div>

        <div class="check__position">
          <div
            v-if="diffCheck"
            class="check__marked"
          >
            <span class="check__tips-heading">Marked positions:</span>

            <span class="check__tips-sum">{{ summaChecked }} &#8364;</span>
          </div>

          <div class="check__tips">
            <span class="check__tips-heading">Tip:</span>

            <span class="check__tips-sum">{{ tips }} &#8364;</span>
          </div>
          <div
            v-if="code != ''"
            class="check__tips"
          >
            <span class="check__tips-heading">Sell 3%:</span>

            <span class="check__tips-sum">{{ discount }} &#8364;</span>
          </div>
        </div>

        <div class="check__totalPay">
          <span class="check__totalPay-heading">Total due:</span>

          <span v-if="diffCheck">{{ summaPlusDiscount }} &#8364;</span>
          <span v-if="!diffCheck">{{ summaPlusDiscount }} &#8364;</span>
        </div>
      </div>

      <form
        action=""
        class="payment"
        @submit.stop.prevent="prevent"
      >
        <button
          class="payment-btn main-btn"
          :disabled="
            (summaChecked == 0 && diffCheck) || (pay && summaChecked == 0)
          "
          @click="showChoicePayment(true)"
        >
          Pay
        </button>

        <button
          class="share-btn main-btn"
          :disabled="diffCheck && summaChecked == 0"
          @click="shareLink"
        >
          Share the bill
        </button>
        <label class="payment__conditions">
          <input
            v-model="checkTransaction"
            type="checkbox"
            checked
            class="payment__conditions-check"
          >
          <p class="payment__conditions-text">
            I want to cover transaction costs ({{ transactionsSumm.toFixed(1) }}
            &#8364;), so that the employee receives the full amount
          </p>
        </label>
        <label class="payment__conditions">
          <input
            type="checkbox"
            checked
            class="payment__conditions-check"
          >
          <div class="payment__conditions-text">
            <p>
              I agree with <a href="#">the terms of the User Agreement</a> and

              <a href="#">the Personal Data Processing Policy</a>
            </p>
          </div>
        </label>
      </form>
    </div>
  </div>
  <div class="menu">
    <div
      v-if="!dishItem"
      class="callWaiter__text"
    >
      <span>Call the waiter</span>
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.001 9.99998L6.00001 15.001L0.999009 9.99998M6.00001 15L6.00001 0.999977L6.00001 15Z"
          stroke="#343D5D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div
      class="callWaiter"
      @click="showCallWaiter(true)"
    >
      <img
        src="~@/assets/images/waiter_icon.svg"
        alt=""
      >
    </div>
  </div>

  <div>
    <choicepayment
      v-if="choicePaymentVisible"
      :sum="summaPay"
      :item="items"
      :diff-check="diffCheck"
      @showChoicePayment="showChoicePayment"
      @showPayment="showPayment"
    />
    <callwaiter
      v-if="callWaiter"
      @showCallWaiter="showCallWaiter"
    />
    <payment
      v-if="paymentVisible"
      :sum="summaPay"
      :item="items"
      :diff-check="diffCheck"
      @showPayment="showPayment"
      @showPaidCheck="showPaidCheck"
      @returnItem="returnItem"
    />
    <sharecheck
      v-if="shareCheckVisible"
      @showShareCheck="showShareCheck"
    />
    <review
      v-if="paidCheckVisible"
      :summa-all="summaAll"
      :summa-pay="summaPay"
      :waiter="waiter"
      :order-id="orderId"
      :table-code="tableCode"
      :tips="tips"
      :summa-plus-discount="summaPlusDiscount"
      @showReview="showReview"
    />

    <!-- <review
      v-if="reviewVisible"
      @showReview="showReview"
    /> -->
    <!-- <paidcheck
      v-if="paidCheckVisible"
      @showPaidCheck="showPaidCheck"
      @showReview="showReview"
    /> -->
    <enterphone
      v-if="enterPhoneVisible"
      @showEnterPhone="showEnterPhone"
      @showEnterCode="showEnterCode"
    />
    <entercode
      v-if="enterCodeVisible"
      @showEnterCode="showEnterCode"
    />
  </div>
</template>

<script>
import VOrderHeader from '@/components/order/OrderHeader'

import Choicepayment from '@/components/demoEn/Choiсepayment'
import Callwaiter from '@/components/demoEn/Callwaiter'
import Payment from '@/components/demoEn/Payment'
import Sharecheck from '@/components/demoEn/Sharecheck'
import Review from '@/components/demoEn/Review'
// import Paidcheck from "@/components/demoEn/PaidCheck"
import Enterphone from '@/components/demoEn/EnterPhone'
import Entercode from '@/components/demoEn/EnterCode'
import axios from 'axios'
export default {
  name: 'Order',
  components: {
    VOrderHeader,

    Choicepayment,
    Callwaiter,
    Payment,
    Sharecheck,
    Review,
    // Paidcheck,
    Enterphone,
    Entercode,
  },

  title: 'Tapper - a service for order management in cafes and restaurants',

  data() {
    return {
      dishItem: false,
      choicePaymentVisible: false,
      callWaiter: false,
      paymentVisible: false,
      shareCheckVisible: false,
      reviewVisible: false,
      pay: false,
      percents: 10,
      summaAll: 0,
      summaChecked: 0,
      summaPay: 0,
      diffCheck: false,
      inputTips: false,
      notPaid: 0,
      manualTips: false,
      checkTransaction: true,
      summTransaction: 0,
      items: [
        {
          id: 0,
          name: 'Pumpkin soup',
          price: 4,
          payed: false,
          active: false,
          count: 2,
          pay: false,
          maxCount: 2,
        },
        {
          id: 1,
          name: 'Scallops with sauce ',
          price: 7,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 2,
          name: 'Pasta bolognese with pesto sauce',
          price: 3,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 3,
          name: 'Cheesecake',
          price: 2,
          payed: false,
          active: false,
          count: 2,
          pay: false,
          maxCount: 2,
        },
        {
          id: 4,
          name: 'Da Luka Rose',
          price: 12,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
      ],
      // items : [],
      tips: 0,
      paidCheckVisible: false,
      enterPhoneVisible: false,
      enterCodeVisible: false,
      code: '',
      dateUpdate: '',
      startAnimation: false,
      subDomen: '',
      tableCode: '',
      waiter: 'Наталья',
      orderId: '',
    }
  },
  computed: {
    transactionsSumm: function () {
      let transactionTips = 0
      let transactionMenu = 0

      transactionTips = (this.tips * 5) / 100
      if (!this.diffCheck) {
        transactionMenu = (this.summaAll * 1.4) / 100
      } else {
        transactionMenu = (this.summaChecked * 1.4) / 100
      }

      this.test(transactionTips, transactionMenu)
      // this.summTransaction = transactionTips + transactionMenu;

      return transactionTips + transactionMenu
    },
    discount: function () {
      if (this.code != '') {
        if (this.diffCheck) {
          return Number(
            (
              (this.summaChecked +
                this.tips +
                (this.checkTransaction ? this.summTransaction : 0)) *
              0.03
            ).toFixed(2)
          )
        } else {
          return Number(
            (
              (this.summaAll +
                this.tips +
                (this.checkTransaction ? this.summTransaction : 0)) *
              0.03
            ).toFixed(2)
          )
        }
      } else {
        return 0
      }
    },
    summaPlusDiscount: function () {
      if (this.diffCheck) {
        return Number(
          (
            this.summaChecked +
            this.tips +
            (this.checkTransaction ? this.summTransaction : 0) +
            this.discount
          ).toFixed(0)
        )
      } else {
        return Number(
          (
            this.summaAll +
            this.tips +
            (this.checkTransaction ? this.summTransaction : 0) +
            this.discount
          ).toFixed(0)
        )
      }
    },
  },
  watch() {},

  mounted() {
    // //данные из консоли
    // let uri = window.location.search.substring(1);
    // let params = new URLSearchParams(uri);
    //
    // //получаем данные по get параметру id_table
    // let requestArray = {
    //   'id_table':params.get("id_table")
    // };
    //
    // let queryString = JSON.stringify(requestArray);
    // axios.post('http://kirill.test-foundarium-tapper.ru/api/order/getorderinfo/', queryString)
    //     .then((response) => {
    //       console.log(response)
    //       const test = response['data']
    //       let tmpItems = [];
    //       test.forEach( function (item, i) {
    //           var id = tmpItems.length + 1;
    //           var found = tmpItems.some(function (el) {
    //             return el.id === item.id;
    //           });
    //            if (!found) {
    //               let counter = 0
    //               test.forEach (function (item2, i2) {
    //                 if (item.id == item2.id) {counter ++}
    //               })
    //               if (counter > 1) {
    //                 tmpItems.push({'id' : item.id, 'name' : item.name, 'price' : item.price/100, 'payed' : false, 'active' : false, 'count': item.quantity/1000 * counter, 'pay' : false, 'maxCount':item.quantity/1000* counter})
    //
    //               }
    //               else {
    //                 tmpItems.push({'id' : item.id, 'name' : item.name, 'price' : item.price/100, 'payed' : false, 'active' : false, 'count': item.quantity/1000, 'pay' : false, 'maxCount':item.quantity/1000})
    //               }
    //            }
    //       });
    //       console.log('tmpItems', tmpItems)
    //       this.items = tmpItems;
    //       //return false;
    //
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    // this.updateInfo();
    this.LoadDishList()
    this.recount()
  },

  methods: {
    validTips(data) {
      console.log('TIPS', data)
      if (data.length && data > 0) {
        this.tips = parseInt(data)
      }
      if (data.length < 1 || parseInt(data) < 1) {
        this.tips = 0
      }
      console.log('data.length', data.length)
    },
    async shareLink() {
      const shareData = {
        url: window.location.href,
      }
      try {
        await navigator.share(shareData)
        console.log('shareData success')
      } catch (err) {
        console.log('share err: ', err)
      }
    },
    test(val1, val2) {
      this.summTransaction = val1 + val2
    },
    updateInfo() {
      this.startAnimation = true
      //данные из консоли
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)

      //получаем данные по get параметру id_table
      let requestArray = {
        id_table: params.get('id_table'),
      }

      let queryString = JSON.stringify(requestArray)
      axios
        .post(
          'http://kirill.test-foundarium-tapper.ru/api/order/getorderinfo/',
          queryString
        )
        .then((response) => {
          console.log(response)
          const test = response['data']['items']
          this.dateUpdate = response['data']['date']
          let tmpItems = []
          test.forEach(function (item, i) {
            var id = tmpItems.length + 1
            var found = tmpItems.some(function (el) {
              return el.id === item.id
            })
            if (!found) {
              let counter = 0
              test.forEach(function (item2, i2) {
                if (item.id == item2.id) {
                  counter++
                }
              })
              if (counter > 1) {
                tmpItems.push({
                  id: item.id,
                  name: item.name,
                  price: item.price / 100,
                  payed: false,
                  active: false,
                  count: (item.quantity / 1000) * counter,
                  pay: false,
                  maxCount: (item.quantity / 1000) * counter,
                })
              } else {
                tmpItems.push({
                  id: item.id,
                  name: item.name,
                  price: item.price / 100,
                  payed: false,
                  active: false,
                  count: item.quantity / 1000,
                  pay: false,
                  maxCount: item.quantity / 1000,
                })
              }
            }
          })
          console.log('tmpItems', tmpItems)
          this.items = tmpItems
          this.startAnimation = false
          //return false;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showChoicePayment(val) {
      this.choicePaymentVisible = val
      this.recount()
    },
    showInputTips() {
      this.inputTips = !this.inputTips
      if (this.tips == '') this.tips = 0
    },
    showCallWaiter(val) {
      this.callWaiter = val
    },

    showPayment(val) {
      this.choicePaymentVisible = false
      this.paymentVisible = val
      setTimeout(() => {
        this.paymentVisible = false
        this.paidCheckVisible = true
      }, 2000)
    },
    returnItem(val) {
      this.items = val
    },

    showShareCheck(val) {
      this.shareCheckVisible = val
    },

    selectPercents(val) {
      this.percents = val
      if (this.diffCheck) {
        this.tips = Math.round((val * this.summaChecked) / 100)
      } else {
        this.tips = Math.round((val * this.summaAll) / 100)
      }
      this.manualTips = false
    },

    showReview(val) {
      this.paidCheckVisible = false
      this.reviewVisible = val
      this.recount()
      // this.pay = true;
      this.percents = 0
    },

    LoadDishList() {
      setTimeout(() => {
        this.dishItem = true
        this.recount()
      }, 5000)
    },
    checkPosition() {
      setTimeout(() => {
        this.recount()
      }, 300)
    },
    plus(id) {
      this.items[id].count += 1
      this.recount()
    },
    minus(id) {
      if (this.items[id].count > 1) {
        this.items[id].count -= 1
        this.recount()
      }
    },
    diffCheckFunc() {
      this.recount()
      this.resetTips()
    },
    recount() {
      // this.resetTips();
      let summa = 0
      this.notPaid = 0
      let self = this
      this.items.forEach(function (item) {
        if (!item.pay) {
          summa = summa + item.price * item.count
          self.notPaid += 1
        }

        // this.summaAll = this.summaAll + item.price
      })

      this.summaAll = summa
      let summaChecked = 0

      let aa = this.items.filter((item) => item.active)
      aa.forEach(function (item) {
        if (!item.pay) {
          summaChecked = summaChecked + item.price * item.count
        }
      })
      this.summaChecked = summaChecked

      if (!this.diffCheck) {
        this.summaPay = this.summaAll
      } else {
        this.summaPay = this.summaChecked
      }

      if (!this.manualTips) {
        this.tips = Math.round((this.percents * this.summaPay) / 100)
      }
    },
    resetTips() {
      this.percents = 0
      this.tips = 0
    },

    showPaidCheck(val) {
      this.paymentVisible = false
      this.paidCheckVisible = val
    },

    showEnterPhone(val) {
      if (this.code == '') {
        this.enterPhoneVisible = val
      }
    },

    showEnterCode(val) {
      this.enterPhoneVisible = false
      this.enterCodeVisible = val.showEnterCode
      this.code = val.code
    },
  },
}
</script>

<style lang="scss">
.container {
  padding: 16px 16px 50px;
}
.myOrder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  &__heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }

  &__table {
    color: #80869a;
  }
}

.dishList__list {
  & .paid {
    border: 1px solid #e6e7eb;
    color: #cccfd7;

    & .dishList__item-status {
      color: #25c26e;
    }

    & .dish__checkbox-text {
      text-decoration: line-through;

      &:before {
        border-color: #dcdee3;
      }
    }

    & .sum {
      color: #cccfd7;
      text-decoration: line-through;
    }

    & .count {
      opacity: 0.5;
    }
  }
}

.divide__check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &-label {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #494c55;
  }

  &-switch {
    position: relative;
    display: inline-block;
    min-width: 48px;
    height: 24px;

    & input {
      display: none;

      &:checked + .slider {
        background-color: #6764ff;
      }

      &:checked + .slider::before {
        transform: translateX(23px);
      }
    }
  }

  & .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7eb;
    transition: 0.4s;
    border-radius: 50px;

    &::before {
      position: absolute;
      content: '';
      width: 19px;
      height: 19px;
      left: 3px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.dishList__item {
  margin-top: 10px;
  padding: 12px;

  border: 1px solid #e6e7eb;
  border-radius: 12px;
  list-style-type: none;

  background-color: #fff;

  &.paid {
    & .dishList__item-dish {
      & .dish__checkbox {
        & input:checked + span {
          background-image: url('~@/assets/images/checked_paid.svg');
        }
      }
    }
  }

  &-status {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #80869a;
  }

  &-dish {
    display: flex;
    justify-content: space-between;

    & .dish__checkbox {
      display: flex;
      align-items: center;

      & span {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.25em;
        margin-right: 21px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked + span {
          background-image: url('~@/assets/images/checked.65527f52.svg');
        }
      }

      &-text {
        display: inline-flex;
        align-items: center;
        max-width: 170px;

        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-total {
    display: flex;
    flex-direction: column;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;

    color: #413dff;

    & .count {
      display: flex;
      align-items: center;
      margin-top: 8px;
      padding: 3px 7px;

      border: 1px solid #edeef1;
      border-radius: 6px;
      background: #f7f8fa;

      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      &__quantity {
        font-size: 13px;
        line-height: 18px;

        color: #80869a;
      }

      &__btn {
        padding: 0;

        outline: none;
        border: none;
        background-color: inherit;
        cursor: pointer;

        & svg {
          vertical-align: middle;
        }
      }

      & span {
        margin: 0 11px;
      }
    }
  }
}

.emptyList {
  margin-top: 130px;
  margin-bottom: 31px;
  padding: 17px 0;

  text-align: center;

  &__icon {
    margin-bottom: 18px;
  }

  &__heading {
    margin: 0 auto;
    margin-bottom: 16px;
    max-width: 298px;

    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  // &__explanation {
  //     margin: 0 auto;
  //     max-width: 279px;

  //     font-weight: 500;
  //     font-size: 16px;
  //     line-height: 20px;

  //     color: #343D5D;
  // }
}

.tips {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 11px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &__heading {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  &__waiter {
    margin-top: 16px;
    margin-bottom: 13px;

    & img {
      margin-right: 12px;
    }

    &-info {
      display: inline-flex;
      flex-direction: column;

      vertical-align: top;

      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #343d5d;

      & span:first-child {
        color: #80869a;
      }
    }
  }

  &__sum {
    position: relative;

    padding-bottom: 19px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    text-align: center;

    border-bottom: 1px solid #edeef1;

    & .custom-input {
      position: absolute;
      left: 50%;

      transform: translateX(-50%);

      & input {
        width: 40px;

        font-weight: 600;
        font-size: 17px;
        line-height: 22px;

        opacity: 0;
      }
    }

    & .close-input {
      position: absolute;
      right: 0;
      z-index: 2;
    }

    &-btn {
      position: absolute;
      right: 0;
      top: 0;

      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      outline: none;
      background: #f7f8fa;
      cursor: pointer;

      border-radius: 50%;
      border: 1px solid #edeef1;
    }
  }

  &__percents {
    padding-top: 18px;

    &-list {
      display: flex;
      justify-content: space-between;
      list-style-type: none;

      & .tips__list-item {
        padding: 4px;

        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #80869a;
        cursor: pointer;

        background: #f7f8fa;
        border-radius: 6px;
        border: 1px solid #f7f8fa;

        &:hover {
          color: #010d35;
          border: 1px solid #6764ff;
        }
      }

      & .active {
        color: #010d35;
        border: 1px solid #6764ff;
      }
    }
  }
}

.check {
  margin-bottom: 24px;
  padding: 12px;

  border: 1px solid #dbdee7;
  border-radius: 11px;

  background-color: #fff;

  &__discount {
    display: block;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #9796b1;
    text-decoration: none;
    &.active {
      color: #6764ff;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    border-bottom: 1px solid #edeef1;
  }

  &__position {
    padding-bottom: 16px;
    padding-top: 8px;

    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    border-bottom: 1px solid #edeef1;

    & .check__tips {
      display: flex;
      justify-content: space-between;

      color: #343d5d;
    }

    & .check__marked {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }
  }

  &__totalPay {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}

.payment {
  margin-bottom: 49px;

  & .payment-btn:disabled {
    color: #020000;
    letter-spacing: 0.03em;
    background: #edeef1;
  }

  & .share-btn {
    color: #343d5d;
    background: #edeef1;
  }

  &__conditions {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #80869a;

    &-check {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + .payment__conditions-text::before {
        background-image: url('~@/assets/images/paid_check.d789c5ba.svg');
      }
    }

    &-text {
      display: inline-flex;
      align-items: center;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.4em;
        margin-right: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #80869a;
      }
    }
  }
}

.menu {
  position: fixed;
  bottom: 0;

  width: 100%;
  padding-bottom: 62px;

  border-top: 1px solid #cccfd7;
  background-color: #fff;

  z-index: 3;

  & .callWaiter__text {
    position: absolute;
    left: 50%;
    top: -97px;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 13px;

    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    transform: translateX(-50%);

    & span {
      margin-bottom: 13px;
    }
  }

  & .callWaiter {
    position: absolute;
    left: 50%;
    top: -50%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    border-radius: 100px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #cccfd7;
    border-right: 1px solid #cccfd7;
    background-color: #fff;
    transform: translateX(-50%) rotate(45deg);

    & img {
      transform: rotate(-45deg);
    }
  }
}

.main-btn {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 12px;

  font-family: 'Commissioner';
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #fff;

  border-radius: 12px;
  border: none;

  outline: none;
  cursor: pointer;

  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
}
.main-btn:disabled {
  color: #000000;
  background: #edeef1;
}
.refresh_icon {
  width: 30px;
  height: 30px;
}
.refresh_icon_time {
  font-weight: 100;
  color: #c8c9cc;
}
.refresh_icon.loading {
  animation: loadingTime 1s linear infinite;
}

@keyframes loadingTime {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
