<template>
  <div
    class="modal__overlay"
    @click="showReview"
  />
  <vue100vh :css="{ height: '100rvh' }">
    <div class="review">
      <div class="container">
        <div class="review__top">
          <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5 32H26.5M29.5 38L24.5 40L19.5 38L14.5 40V14H34.5V40L29.5 38ZM20.5 20H28.5H20.5ZM28.5 26H20.5H28.5Z"
              stroke="#A7ACBC"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M34.5 22H38.5C40.71 22 42.5 20.21 42.5 18V10C42.5 7.79 40.71 6 38.5 6H10.5C8.29 6 6.5 7.79 6.5 10V18C6.5 20.21 8.29 22 10.5 22H14.5"
              stroke="#A7ACBC"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="review__status">
            Order status: paid
          </div>
          <div class="review__time">
            {{ currentDate }}
          </div>
        </div>
        <h2 class="review__title">
          Did you enjoy everything?
        </h2>

        <div class="review__stars">
          <div
            class="review__stars-item"
            style="display: none"
            :class="{ active: rating == 0 }"
            @click="handleRating(1)"
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="review__stars-item"
            :class="{ active: rating == 1 }"
            @click="handleRating(1)"
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="review__stars-item"
            :class="{ active: rating == 2 }"
            @click="handleRating(2)"
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="review__stars-item"
            :class="{ active: rating == 3 }"
            @click="handleRating(3)"
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="review__stars-item"
            :class="{ active: rating == 4 }"
            @click="handleRating(4)"
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="review__stars-item"
            :class="{ active: rating == 5 }"
            @click="handleRating(5)"
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 15.235L4.679 18L5.888 11.88L1.5 7.392L7.679 6.621L10.5 1L13.321 6.621L19.5 7.392L15.112 11.88L16.321 18L10.5 15.235Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <ul
          v-if="rating > 3"
          class="review__list"
        >
          <li
            v-for="(reviewItem, index) in reviewItems"
            :key="reviewItem.id"
            class="review__list-item"
            :class="{ active: reviewItem.active }"
            @click="addActive(index)"
          >
            <img
              :src="require('../../assets/images/' + reviewItem.image)"
              alt=""
            >

            <span>{{ reviewItem.text1 }}</span>

            <span>{{ reviewItem.text2 }}</span>
          </li>
        </ul>

        <div
          v-if="rating && rating <= 3 && rating > 0"
          class="suggestions"
        >
          <p class="suggestions__title">
            What can we do better?
          </p>

          <ul class="suggestions__list">
            <li
              v-for="(item, index) in reviewItemsCheckbox"
              :key="item.id"
              class="suggestions__list-item"
            >
              <label class="suggestions__list-checkbox">
                <span>{{ item.text }}</span>

                <input
                  v-model="reviewItemsCheckbox[index].active"
                  type="checkbox"
                >

                <span class="custom-checkbox" />
              </label>
            </li>

            <!-- <li class="suggestions__list-item">
              <label class="suggestions__list-checkbox">
                <span>Чистота</span>

                <input type="checkbox">

                <span class="custom-checkbox" />
              </label>
            </li>

            <li class="suggestions__list-item">
              <label class="suggestions__list-checkbox">
                <span>Атмосфера</span>

                <input type="checkbox">

                <span class="custom-checkbox" />
              </label>
            </li>

            <li class="suggestions__list-item">
              <label class="suggestions__list-checkbox">
                <span>Качество блюд</span>

                <input type="checkbox">

                <span class="custom-checkbox" />
              </label>
            </li> -->
          </ul>
        </div>

        <p class="review__text">
          Comment
        </p>

        <textarea
          v-model="review"
          class="review__textarea"
          name="review"
          placeholder="Tell us more..."
        />

        <button
          class="main-btn"
          @click="showReview"
        >
          Send feedback
        </button>
      </div>
    </div>
  </vue100vh>
</template>

<script>
import { mapActions } from 'vuex'
/* eslint-disable */
import vue100vh from 'vue-100vh'
export default {
  name: 'Review',
  components: { vue100vh },
  props: [
    'summaAll',
    'summaPay',
    'waiter',
    'orderId',
    'tableCode',
    'summaPlusDiscount',
    'tips',
  ],

  data() {
    return {
      tags: '',
      poddomen: '',
      currentDate: '',
      rating: 0,
      review: '',
      reviewItemsCheckbox: [
        { id: 0, text: 'Service', active: false },
        { id: 1, text: 'Clean', active: false },
        { id: 2, text: 'Atmosphere', active: false },
        { id: 3, text: 'Food quality', active: false },
      ],
      reviewItems: [
        {
          id: 0,
          image: 'atmosfera.png',
          text1: 'Wonderful',
          text2: 'atmosphere',
          active: false,
        },
        {
          id: 1,
          image: 'clean.png',
          text1: 'Very',
          text2: 'clean',
          active: false,
        },
        {
          id: 2,
          image: 'interier.png',
          text1: 'Beautiful',
          text2: 'interior',
          active: false,
        },
        {
          id: 3,
          image: 'service.png',
          text1: 'Excellent',
          text2: 'service',
          active: false,
        },
        {
          id: 4,
          image: 'dish.png',
          text1: 'Gourment',
          text2: 'meals',
          active: false,
        },
      ],
    }
  },

  mounted() {
    const full = window.location.host
    const parts = full.split('.')
    this.poddomen = parts[0]
    this.getDate()
  },

  methods: {
    ...mapActions('send', ['TEST_SEND']),
    handleRating(val) {
      this.rating = val
    },

    handleReview(val) {
      this.review = val
    },

    async showReview() {
      console.log('showReview')
      this.tags = ''
      const rew = this.reviewItems.filter((i) => i.active)
      const rew2 = this.reviewItemsCheckbox.filter((i) => i.active)
      if (this.rating > 3 && rew.length > 0) {
        rew.forEach((i, index) => {
          let zpt = index + 1 == rew.length ? ' ' : ', '
          this.tags += i.text1 + ' ' + i.text2 + zpt
        })
      }
      if (this.rating > 0 && this.rating < 4 && rew2.length > 0) {
        rew2.forEach((i, index) => {
          let zpt = index + 1 == rew2.length ? ' ' : ', '
          this.tags += i.text + zpt
        })
      }
      console.log('this.tags', this.tags)
      console.log('orderId', this.orderId)
      console.log('tableCode', this.tableCode)
      await this.sendMsg()
      this.$emit('showReview', false)
    },

    addActive(index) {
      this.reviewItems[index].active = !this.reviewItems[index].active
    },

    sendMsg() {
      let pozelaniya =
        this.tags.length > 0 ? 'Что понравилось: ' + this.tags + '\n' : ''
      let text =
        'Комментарий: ' +
        this.review +
        '\n' +
        'Рейтинг: ' +
        this.rating +
        '\n' +
        pozelaniya +
        'Заказ# ' +
        this.orderId +
        '\n' +
        'Стол: ' +
        this.tableCode +
        '\n' +
        'Официант: ' +
        this.waiter +
        '\n' +
        'Сумма в чеке: ' +
        this.summaPlusDiscount +
        'р' +
        '\n' +
        'Чаевые: ' +
        this.tips +
        'р' +
        '\n' +
        'Статус оплаты: оплачено' +
        '\n' +
        'Дата заказа: ' +
        this.currentDate
      const req = {
        domen: this.poddomen,
        message: text,
      }
      console.log('req', req)
      this.TEST_SEND(req)
    },
    getDate() {
      const date = new Date()
      const seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const hours = date.getHours()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      this.currentDate = `${day}.${month}.${year} | ${hours}:${minutes}:${seconds}`
    },
  },
}
</script>

<style lang="scss">
.review {
  position: fixed;
  top: 0;
  padding-top: 12px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  height: 100vh;
  z-index: 2;

  padding-bottom: 45px;
  &__wrapper {
    min-height: 244px;
  }
  .container {
    height: 100%;
    overflow: auto;
    padding-bottom: 80px;
  }
  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    max-width: 320px;
    border-bottom: 1px solid #edeef1;
    margin: 0 auto 16px auto;
    svg {
      margin-bottom: 24px;
    }
  }
  &__status {
    color: #010d35;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  &__time {
    color: #4e5672;
    font-size: 13px;
    line-height: 18px;
  }

  &__title {
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #010d35;
    text-align: center;
  }

  &__stars {
    display: flex;
    justify-content: space-between;
    width: 162px;
    margin: 0 auto;
    margin-bottom: 28px;

    &-item {
      & svg {
        fill: #ffd140;
        stroke: #ffd140;
      }

      &.active {
        & ~ * {
          svg {
            fill: #edeef1;
            stroke: #edeef1;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    margin-bottom: 24px;

    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    overflow-x: scroll;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 16px;

      list-style-type: none;

      color: #80869a;

      & img {
        opacity: 0.4;
      }

      &.active {
        span {
          color: #010d35;
        }

        Img {
          opacity: 1;
        }
      }
    }
  }

  .suggestions {
    &__title {
      margin-bottom: 12px;

      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #343d5d;
      text-align: center;
    }

    &__list {
      margin-bottom: 24px;

      &-item {
        margin-bottom: 12px;

        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #343d5d;

        list-style-type: none;

        &:last-child {
          margin-bottom: 0;
        }

        & .suggestions__list-checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked + .custom-checkbox {
              background-image: url('~@/assets/images/checked.65527f52.svg');
            }
          }

          & .custom-checkbox {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1.5px solid #dcdee3;
            border-radius: 0.25em;
            margin-right: 21px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 12px;
          }
        }
      }
    }
  }

  &__text {
    padding-bottom: 8px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #343d5d;

    border-bottom: 1px solid #edeef1;
  }

  &__textarea {
    width: 100%;
    height: 120px;
    margin-bottom: 24px;

    border: none;
    outline: none;
    resize: none;

    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    &::placeholder {
      font-family: Commissioner;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
  }

  // &__send-btn {
  //     width: 100%;
  //     padding: 16px 0;
  //     margin-bottom: 12px;

  //     font-weight: 600;
  //     font-size: 17px;
  //     line-height: 22px;
  //     color: #343D5D;

  //     background: #EDEEF1;
  //     border-radius: 12px;
  //     border: none;
  // }
}
</style>
